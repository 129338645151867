@import "../../assets/css/variables.scss";

.page-my-calender {
    .calendar-wraper {
        height: 90vh;
        background-color: #fff;
    }
    .rbc-month-view .rbc-month-row:nth-child(2){
       
        .rbc-row-bg{border-radius: 15px 15px 0 0;}
    }

    .prev-dates-col {
        margin-top: 40px;

        .date-box {
            height: 148px;
            display: flex;
            align-items: center;
            font-weight: $font-medium;
            color: $color-grey;
        }
    }

    .rbc-calendar {
        .rbc-event {
            background-color: transparent !important;
        }
    }

    .rbc-row-content {
        min-height: 110px !important;
    }

    .rbc-month-row {
        overflow: visible !important;
        border-radius: 15px;
        
        &:last-child {
            .rbc-row-bg {
                border-radius: 10px;
            }
        }
    }
    

    .rbc-row-bg {
        background-color: #fff;
    }

    .rbc-row-bg,
    .rbc-row-content {
        // max-height: 130px;
        // overflow-y: scroll !important;
    }

    .rbc-month-view {
        border: 0;
        box-shadow: 0px 0px 14px #0000001c;
        border-radius: 14px;
        border: 1px solid #F2F2F2;
    }

    .rbc-day-bg.rbc-off-range-bg {
        background-color: transparent;
    }

    .rbc-off-range {
        color: #DBDBDB;
    }

    .rbc-day-bg+.rbc-day-bg,
    .rbc-month-row+.rbc-month-row,
    .rbc-header+.rbc-header {
        border-color: #F2F2F2;

    }

    .rbc-month-header {
        .rbc-header:first-child {
            border-radius: 15px 0 0 0;
        }

        .rbc-header:last-child {
            border-radius: 0 15px 0 0;
        }
    }

    .rbc-header {
        border-bottom: 0;
        text-transform: uppercase;
        font-weight: 400;
        text-align: right;
        font-size: 14px;
        padding: 8px 9px;
        background-color: #fff;
    }

    .rbc-date-cell {
        text-align: left;
        padding: 5px 5px 0px;

        &.rbc-current {
            .rbc-button-link {
                background-color: $color-secondary;
                width: 24px;
                height: 24px;
                color: #fff;
                border-radius: 16px;
            }
        }
    }

    .rbc-day-bg.rbc-today {
        background-color: #fff;
    }

    .rbc-show-more {
        text-align: center;
        width: 100%;
        color: $color-primary;
        font-size: 13px;
        font-weight: $font-medium
    }

    .rbc-event {
        background-color: transparent;
        padding: 2px 0;
    }

    .calendar-nave {
        align-items: center;

        .next-prev-date {
            display: flex;
            gap: 5px;
            align-items: center;
            background: #fff;
            box-shadow: 0px 0px 4px #00000017;
            border-radius: 6px;

            .btn {
                background-color: transparent;
                color: #333;
                padding: 10px 11px;
                line-height: 20px;
                border-radius: 0;
                border: 0;

            }

            .date-picker {
                min-width: 168px;
                text-align: center;
                font-size: 18px;
            }
        }

        .check-btn {
            background-color: #fff;
            padding: 8px 19px 8px 38px;
            border-radius: 6px;
            box-shadow: 0px 0px 4px #00000017;

            &.Event {
                .form-check-input:checked {
                    background-color: #A48EE5;
                    border-color: #A48EE5;
                }
            }

            &.Reminder {
                .form-check-input:checked {
                    background-color: #0FC000;
                    border-color: #0FC000;
                }
            }

            &.Planner {
                .form-check-input:checked {
                    background-color: #02A0FC;
                    border-color: #02A0FC;
                }

            }
        }
    }
    .rbc-row.rbc-month-header{
        margin-bottom: -34px;
        background-color: #f35b5b00;
        z-index: 1;
        border-radius: 15px;
    }
}

.date-detail-modal {
    .event-description {
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
.modal-add-event{
    .modal-dialog{max-width: 410px;}
}