.resultLoading {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000000000000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
    }
    
    .loader-bg {
    background: '#000000';
    opacity: 0.7;
    width: 100%;
    height: 100% !important;
    position: absolute;
    top: 0
    }