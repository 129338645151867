.login-page {
  .input-group{
    .form-control{
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0;
    }
    .input-group-text{background-color: transparent;}
  }
  .login-card{
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }
  .login-wrap-min-h{
    min-height: calc(100vh - 193px);
  }
  input:-internal-autofill-selected {
background-color: transparent !important;
  }
  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
}