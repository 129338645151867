:root {
    --color-progress: #8A9FD4;
    --color-progress-alpha: #8A9FD4;
}

.liq-progress {
    .description {
        margin-top: 45px;
    }

    .progress1 {
        --progress-value: 50;
        --progress-indicator-size: 350px;
        --progress-size: 160px;
        --progress-inner-size: calc(var(--progress-size) - 10px);
        border: 3px solid #8a9fd452;
    }

    .progress1::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        border-radius: 50%;
        min-width: 8px;
        min-height: 8px;
        transform: translate(-50%, -50%);
    }

    .progress1[data-value="100"] {
        --color-progress: #557c55;
        --color-progress-alpha: #a6cf98;
    }

    .progress1[data-value="100"]::before {
        -webkit-animation: progress-firework 1.3s 0.1s ease infinite both;
        animation: progress-firework 1.3s 0.1s ease infinite both;
    }

    .progress1,
    .progress-inner {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-content: center;
        width: var(--progress-size);
        height: var(--progress-size);
        border-radius: var(--progress-size);
        background: var(--color-gray);

    }

    .progress-inner {
        width: var(--progress-inner-size);
        height: var(--progress-inner-size);
        border: none;
        margin: auto;
        overflow: hidden;
        border: 3px solid #F2F2F2;
    }

    .progress--upper-half-value {
        color: var(--color-light-gray) !important;
    }

    .progress-label {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        transform: translate(-50%, -50%);
        display: inline-block;
        color: #fff;
        text-align: center;
        overflow: hidden;
        font-size: 24px;

        span {
            font-size: 24px !important;
        }
    }

    // .progress-label strong {
    //     font-size: 200%;
    // }

    .progress-label span {
        font-size: 120%;
    }

    .progress-indicator {
        background: var(--color-progress);
        transform-origin: center center;
        opacity: 0.6;
        -webkit-animation: progress-wave-animation 12s linear infinite both;
        animation: progress-wave-animation 12s linear infinite both;
        position: absolute;
        top: calc(100% - var(--progress-value) * 1%);
        left: -50%;
        right: 50%;
        width: var(--progress-indicator-size);
        height: var(--progress-indicator-size);
        border-radius: calc(var(--progress-indicator-size) / 2.5);
    }

    .progress-indicator:nth-child(2) {
        // background: var(--color-progress-alpha);
        transform: rotate(90deg);
        border-radius: 100px;
        background: linear-gradient(180deg, #8A9FD4 0%, #5C5FA1 100%);
    }

    @-webkit-keyframes progress-firework {

        from,
        0% {
            width: 50%;
            height: 50%;
            background: var(--color-progress);
            opacity: 1;
        }

        30% {
            opacity: 1;
        }

        to,
        100% {
            width: 150%;
            height: 150%;
            background: var(--color-progress-alpha);
            opacity: 0;
        }
    }

    @keyframes progress-firework {

        from,
        0% {
            width: 50%;
            height: 50%;
            background: var(--color-progress);
            opacity: 1;
        }

        30% {
            opacity: 1;
        }

        to,
        100% {
            width: 150%;
            height: 150%;
            background: var(--color-progress-alpha);
            opacity: 0;
        }
    }

    @-webkit-keyframes progress-wave-animation {

        to,
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes progress-wave-animation {

        to,
        100% {
            transform: rotate(360deg);
        }
    }

    *:not(.watermark) {
        z-index: 2;
    }

    .description {
        display: flex;
        max-width: 300px;
        place-items: center;
        justify-content: space-between;
    }

    .description input {
        max-width: 30%;
    }

    .watermark {
        margin-top: 2rem;
        color: var(--color-primary);
        align-self: start;
    }
}