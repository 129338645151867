@import '../../assets/css/variables.scss';
.page-my-details{
    .my-detail-cards{
        min-height: 371px;
        .card-body{padding: 24px;}
    }
    .tab-nav-card-shadow{
        box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);
    }
    .tab-style-2{
        .nav-item{
            .nav-link{background-color: transparent !important;
                transition: 0.3s;
                &.active{background-color: #fff !important;}
            }
        }
    }
    .about-card-min-h{
        min-height: calc(100vh - 134px);
    }
    .app-body{
        background: #fff !important;
    }
    .detail-card-min-h{
        min-height: calc(100vh - 134px);
    }
    .about-tab-col{
        width: 438px;
        .card.card-shadow{
            box-shadow: 10px 0px 13px -9px #00000017 !important;
        }
    }
    .nav-item a span:hover, .nav-item a.nav-link:hover{
        color:$color-primary-dark !important;

        .icon{
            filter: grayscale(0);
            opacity: 1;
            transition: 0.3s;
        }
    }
   
    @media (max-width: 767px) {
        .my-detail-cards{
            min-height: auto;
        }
    }
}