@import "../../assets/css/variables.scss";

.page-onboarding {
    .container{
        max-width: 1248px;
    }
    // pophover 
    .pophover-Whatdoyouplan{
        transform: translate(25px, -47px) !important;
    }
    .pophover-Areyouapplying{
        transform: translate(25px, -70px) !important;
    }
   .pophover-Collegelegacy{
        transform: translate(25px, 0) !important;
        min-width: 530px;
        top: -78px !important;
    }
   .pophover-Willyouneedfinancial{
        transform: translate(24px, -36px) !important;
         
    }
   .pophover-Raceorethnicity{
        transform: translate(24px, -45px) !important;
        min-width: 330px;
    }
   .pophover-Startmeeting{
        transform: translate(24px, -90px) !important;
        min-width: 480px;
        padding: 24px;
    }
    .left-tab-col{
        max-width: 462px;
        @media (max-width:767px) {
            max-width: 100%;
        }
    }
    .right-tab-content-col{
        max-width: 774px;
        @media (max-width:767px) {
            max-width: 100%;
        }
    }
    background-color: #fff;
    min-height: 100vh;
    .logo-header {
        padding-top: 70px;
        padding-bottom: 36px;
        text-align: center;
    }
    .row-min-vh {
        min-height: calc(100vh - 303px);
       
    }

    &.welcome-page {
        background-color: #F4F7FD;
        min-height: 111vh;
        .onboarding-form-contianer{
            max-width: 460px;
        }
        .row-min-vh {
            min-height: auto;
        }

        .logo-header {
            padding-top: 75px;
            padding-bottom: 120px;
            text-align: center;
        }

        .welcome-card {
            max-width: 460px;
            margin: 0 auto;
        }
        @media (max-width: 767px) {
            .row-min-vh {
                height: auto;
            }

            .logo-header {
                padding-top: 45px;
                padding-bottom: 45px;

                .logo {
                    height: 40px;
                    width: 140px;
                }
            }

            .welcome-card {
                border-radius: 0;
                box-shadow: none;
                padding: 0;
                background-color: transparent;
            }

            .container {
                padding: 0 20px;
            }

        }
    }
    &.step-6{
        .logo-header{
            padding-bottom: 50px;
        }
        .card{max-width: 460px;}
    }

    .onboarding-form-contianer {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .slider-page-container {
        max-width: 1036px;
        margin: 0 auto;
    }

    .onbaording-step {
        margin: 40px 0;
        display: flex;
        justify-content: center;
        gap: 12px;
        align-items: center;

        .step-item {
            display: flex;
            align-items: center;
            gap: 8px;

            .step-no {
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #ddd;
                color: #ddd;
                font-size: 14px;
                border-radius: 30px;
                line-height: 14px;
            }

            .step-name {
                font-size: 16px;
                color: #929295;
            }

            &.active {
                .step-no {
                    background-color: $color-primary;
                    color: #fff;
                    border-color: $color-primary;
                }

                .step-name {
                    color: #333;
                }
            }

            &.completed {
                .step-no {
                    border-color: $color-primary;
                    color: $color-primary;
                }

                .step-name {
                    color: #333;
                }
            }
        }

        .connector {
            height: 1px;
            background-color: #DBDBDB;
            width: 30px;
        }
    }

    .grade-options-btns {
        display: flex;
        gap: 15px;

        .btn {
            width: 155px;
        }

        .btn-check:checked+.btn {
            background-color: $color-primary-light !important;
        }
    }

    .progress-steps {
        position: absolute;
        left: 120px;
        top: 36%;

        @media (max-width: 767px) {
            position: initial;
        }
    }

    @media (max-width: 767px) {
        .onbaording-step {
            display: block;
        }

        .connector {
            width: 1px !important;
            height: 30px !important;
            margin-left: 11px;
        }
    }


    @media (max-width: 767px) {
        .logo-header {
            padding-top: 45px;

        }

        .container {
            padding: 0 25px;
        }

        .logo {
            height: 40px;
            width: 140px; 
        }
    }

}

.react-select-open.selected {
    .form-control {
        border-color: $color-secondary-2;
        padding-right: 30PX;
    }
}

.react-date-select.selected {
    .form-control {
        border-color: $color-secondary-2;
    }
}

.calculate-card {
    border: 2px solid $color-primary-light;
    padding: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.3s;
}