 .page-university-search {
   .filter-dd {
    .dropdown-toggle{
     &::after {
       content: none;
     }
     &.show{background-color: #fff;border-color: #DBDBDB;border-radius: 6px 6px 0 0;}
    }

     .dropdown-menu {
       width: 278px;
       padding: 10px;
       border: 1px solid #DBDBDB;
       top: -3px !important;
       border-radius: 0 0 6px 6px;
     }

     .accordion {
       .accordion-button {
         padding: 0;
         font-size: 16px;
         background-color: transparent;
         box-shadow: none;
         margin-bottom: 4px;

         &::after {
           width: 12px;
           height: 12px;
           background-size: 12px;
         }

         &:focus {
           box-shadow: none;
         }
       }

       .accordion-item {
         border: 0;
         margin-bottom: 8px;
       }

       .accordion-body {
         padding: 0;
       }
     }

     &.competitiveness{
      .btn{width: 145px;}
      .dropdown-menu{
        padding: 0;
        max-width: 232px;
        min-width: 232px;
      }
      .dropdown-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
        color: #000;
        svg{opacity: 0.5;}
      }
     }

     &.area-study{
      .search-bar{
        border: 1px solid #f3f3f3;
        filter: drop-shadow(-2px 4px 29px rgba(0, 0, 0, 0.05));
      }
      &.location{
        .dropdown-menu{
          width: auto;
        }
      }
     }
   }

   .university-card {
     border-radius: 12px !important;

     .university-photo {
       height: 250px;
       width: 100%;
       border-radius: 12px 0 0 12px !important;
       @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) { border-radius: 12px 12px 0 0 !important}
     }
     .university-name {
       font-size: 24px;
       font-weight: 700;
     }

     .description {
       font-size: 14px;
       color: #929295;
       margin-bottom: 35px;
     }

     .card-body {
       padding: 24px 24px;
       height: 100%;
     }

     .img-col {
       max-width: 235px;

       @media (max-width: 767px) {
         max-width: 100%;
       }
       @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {max-width: 100%;}

   }
   
  }
  .rangeslider{
    margin: 10px 0;
    height: 4px !important;
    .rangeslider__handle{
      height: 12px !important;
      width: 12px !important;
    }
   }
 }

