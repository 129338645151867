@import "../../assets/css/variables.scss";

.signup-page {
  .accout-type-card {
    transition: 0.3s;
    box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    padding: 20px 25px;
    border: 2px solid #F2F2F2;
    border-radius: 8px;
    cursor: pointer;

    .icon {
      color: $color-secondary-2;
    }

    &:hover, &.active {
      background-color: $color-primary-light;
      border-color: $color-secondary-2;
    }
  }



  .input-group {
    .form-control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0;
    }

    .input-group-text {
      background-color: transparent;
    }
  }
}