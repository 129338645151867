$color-primary: #5d61a2;
$color-primary-dark: #25156E;
$color-primary-light: #F4F7FD;
$color-secondary: #A48EE5;
$color-secondary-light: #C0AFED;
$color-secondary-2: #8A9FD4;
$color-grey: #929295;
$form-control-border: #DBDBDB;

$font-bold: 700;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;