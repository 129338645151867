@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import "../css/variables.scss";

body {
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 400;
    zoom: 0.9;

}

.font-family {
    font-family: 'Outfit', sans-serif;
}

.font-8 {
    font-size: 8px;
}

.font-10 {
    font-size: 10px;
}

.font-12 {
    font-size: 12px !important;
}

.font-14 {
    font-size: 14px;
    line-height: 150%;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px;
    line-height: 21px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px !important;
    line-height: 30px;
}

.font-28 {
    font-size: 28px;
}

.font-36 {
    font-size: 36px;
}

.heading-1 {
    font-size: 29px;
    font-weight: 500;
}

.font-bold {
    font-weight: $font-bold !important;
}

.font-medium {
    font-weight: $font-medium;
}

.font-light {
    font-weight: $font-light;
}

.font-regular {
    font-weight: $font-regular;
}

.modal-backdrop {
    width: 111.5vw;
    height: 111.5vh;
}

a {
    text-decoration: none;
    transition: 0.4s;
    color: $color-primary;

    &:hover {
        color: $color-secondary !important;
    }
}

.text-brand {
    color: $color-primary;
}

.text-brand-2 {
    color: $color-secondary-light;
}

.text-brand-3 {
    color: $color-secondary-2;
}

.text-brand-dark {
    color: $color-primary-dark;
}

.text-success {
    color: #0FC000 !important;
}

.text-secondary {
    color: $color-grey !important;
}

.border-brand {
    border-color: $color-primary !important;
}

.border-brand-2 {
    border-color: $color-secondary !important;
}

.bg-brand-light {
    background-color: $color-primary-light !important;
}

.bg-light-gray {
    background-color: #F2F2F2 !important;
}

select:invalid {
    color: gray;
}

.gx-05 {
    --bs-gutter-x: 2px;
}

// popover -----
#start_meeting_popover {
    inset: 4.5vh auto auto 6.5vw !important;
}

.mx-w-360 {
    max-width: 460px !important;
}

.date-picker {
    width: 100% !important;

    .react-datepicker-wrapper {
        width: 100% !important;
        box-sizing: border-box;

        .react-datepicker__input-container {
            height: 44px;

            div {
                height: 44px;
                display: flex;
                align-items: center;
            }
        }

        padding-bottom: 0px;
        border-radius: 5px;

        .calander-icon {
            width: 18px;
            margin-left: auto;
            opacity: 0.5;
        }

        &:hover .calander-icon {
            opacity: 1;
        }
    }

}

.react-datepicker__header {
    background-color: $color-primary-light !important;

    .react-datepicker__header__dropdown {
        padding: 5px;
    }

    select {
        border-radius: 4px;
    }
}

.react-datepicker__month {
    .react-datepicker__day--keyboard-selected {
        background-color: $color-primary !important;
        color: #fff !important;
    }
}

.date-time-picker {
    .react-datepicker {
        width: auto;

        .react-datepicker__navigation.react-datepicker__navigation--previous {
            right: 130px;
        }

        .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
            right: 100px;
        }

        .react-datepicker-time__header {
            font-size: 14px;
            font-weight: 500;
            color: #0f0f0f;
        }

        .react-datepicker__current-month {
            display: block;
            font-weight: 400;
            font-size: 12px;
        }
    }



}

.btn-brand-1 {
    background-color: $color-primary;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    font-weight: 500;
    height: 42px;
    border: 0;
    padding: 7px 27px;
    line-height: 25px;
    border-radius: 8px;

    &:hover {
        background-color: $color-primary-dark;
        color: #fff !important;
    }

    &:disabled {
        // background-color: #d3d0d0;
        background-color:#DBDBDB;
        color: #fff;
        border-color: transparent
    }

    &.btn-lg {
        height: 50px;

    }
}

.hover-effect:hover {
    opacity: 1 !important;
    color: $color-primary !important;
}

.btn-outline-brand {
    @extend .btn-brand-1;
    background-color: #fff;
    border: 1px solid $color-primary;
    color: $color-primary;

    &:hover {
       box-shadow: 0px 0px 10px #00000014;
       background-color: #fff;
       color: $color-primary !important;
    }
    
}

a.btn-outline-brand {
    display: inline-block;
    &:hover{border: 1px solid $color-primary !important;}
}

.create-btn {
    border-radius: 8px;
    border: 1px dashed #ddd;
    padding: 15px;
    color: #929295;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;

    &:hover,
    &:focus-visible {
        border-color: $color-primary;
        color: $color-primary;
        box-shadow: none;
    }
}

.border-light {
    border-color: #F2F2F2 !important;
}

.option-dd {
    .btn {
        background: transparent;
        border: 0;
        padding: 0;
        color: $form-control-border;

        &::after {
            border: 0;
        }

        &:hover {
            color: $color-primary;
        }
    }
}

.btn-outline-light {
    background-color: transparent;
    border: 1px solid $form-control-border;
    color: #000;
    font-size: 14px;
    padding: 7px 14px;
    border-radius: 7px;
    height: 42px;
}

.view-btns {
    .btn {
        border-color: $form-control-border;
        color: $form-control-border;
        padding: 4px 11px;
    }

    .btn-check:checked+.btn,
    :not(.btn-check)+.btn:active,
    .btn:first-child:active {
        background-color: #fff;
        color: $color-primary !important;
        border-color: $form-control-border;

        &:hover {
            color: $color-primary !important;
            border-color: $form-control-border;
        }
    }

    .btn-check:checked+.btn:hover {
        color: $color-primary;
    }
}

.tag-badge {
    background-color: $color-secondary-2;
    color: #fff;
    border-radius: 15px;
    font-weight: $font-medium;
    padding: 4px 10px;
    line-height: 16px;
    cursor: pointer;
    transition: 0.4s;
    ;

    &:hover {
        background-color: $color-primary;
    }
}

.custom-input-search {
    .search-result {
        background-color: #fff;
        padding: 15px;
        box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);
        border-radius: 0 0 8px 8px;
    }
}

.li-line-h-200 {
    line-height: 200%;
}

.btn-tabs-nav {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-primary-light;
    border-radius: 100px;
    border: 0;


    &:hover {
        background-color: #fff;
        box-shadow: 0px 0px 12px #00000012;
    }

    &::after {
        border: 0;
        content: none !important;
    }
}

.select-option-btns {
    gap: 20px;
    flex-wrap: wrap;

    .btn {
        border-radius: 30px !important;
        font-size: 11px;
        background-color: #F9F9F9;
        color: #000;
        border-color: $color-primary-light;
        flex: none;

        &:hover {
            background-color: $color-primary-light;
            color: $color-primary;
            border-color: $color-primary-light !important;
        }
    }

    .btn-l {
        border-radius: 20px !important;
        font-size: 14px;
        border-color: $color-primary-light
    }

    .btn-check:checked+.btn {
        background-color: $color-primary-light;
        border-color: $color-primary;
        color: $color-primary;

        &:hover {
            color: $color-primary !important;
            border-color: $color-primary !important;
        }
    }

    &.btn-group-vertical {
        .btn {
            width: auto;
            min-width: 70px;
        }
    }

    &.square {
        .btn {
            border-radius: 16px !important;
            padding: 10px 40px;
            background-color: #F9F9F9 !important;

            @media (max-width: 767px) {
                background-color: #fff !important;
            }
        }
    }

    &.font-16 {
        .btn {
            font-size: 16px;
        }
    }

    &.font-18 {
        .btn {
            font-size: 18px;
        }
    }

    &.font-14 {
        .btn {
            font-size: 14px;
        }
    }

    &.report-btns {
        .btn {
            width: 48%;
            border-radius: 12px !important;

            &:hover {
                background-color: #FFEAE9;
                color: #FF3A29 !important;
            }
        }

        .btn-check:checked+.btn {
            background-color: #FFEAE9;
            color: #FF3A29;
            border-color: #FF3A29;
        }
    }
}
 
// left menu css
.layout-veritcle-menu {
    display: flex;
    min-height: 100vh;

    .verticle-menu {
        background-color: #fff;
        box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);
        width: 80px;
        height: 111vh;
        position: fixed;
        top: 0;
        z-index: 10;
        padding: 15px 18px;
        transition: 0.3s;
        overflow-y: auto;

        

        .menu-list {
            background: #F9F9F9;
            border-radius: 17px;
            padding: 2px 2px;

            .menu-item {
                transition: 0.3s;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 16px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 12px;
                color: #7a7979;
                font-size: 19px;

                .menu-icon {
                    width: 18px;
                    height: 18px;
                    filter: grayscale(100%);
                }

                &.active,
                &:hover,
                &:focus {
                    background-color: #fff;
                    color: $color-secondary;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

                    .menu-icon {
                        filter: grayscale(0);
                    }
                }

                &:last-child {
                    margin-bottom: 0
                }

                .menu-name {
                    font-size: 12px;
                    // display: none;
                    width: 0px;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }



            .dropdown-toggle {
                @extend .menu-item;
                border: 0;
                background-color: transparent;
                width: 100%;
                border-radius: 6px 0 0 6px;

                &::after {
                    border: 0;
                }

                &.show {
                    background-color: #1C1C1C;
                    opacity: 1;
                }
            }

            .dropend {
                text-align: center;
            }

            .dropdown-menu {
                left: -3px !important;
                border-radius: 0 6px 6px 0;
                background-color: #1C1C1C;

                .dropdown-item {
                    color: #fff;
                    font-size: 14px;
                    padding: 9px 13px;
                    margin-bottom: 3px;

                    &:hover {
                        background-color: #ffffff2b;
                        color: #ffffff !important;
                    }
                }
            }

            &.bottom {
                background-color: transparent;

                .menu-icon {
                    width: 18px;
                }
            }
        }



        .tooltip-inner {
            background-color: #343434 !important;
        }

        &:hover {
            width: 245px;

            .menu-name {
                // display: block !important;
                width: 100% !important;
            }

            .menu-item {
                width: auto;
                justify-content: flex-start;
                gap: 6px;
                padding: 10px;
            }
        }

        .menu-wraper {
            display: flex;
            flex-direction: column;
            height: calc(100% - 40px);
        }
    }

    .app-body {
        // width: calc(100% - 75px);
        padding-top: 105px;
        padding-bottom: 25px;
        padding-left: 95px;
        width: 100%;

        @media (max-width: 767px) {
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 70px;
        }
    }
}

// header 

.site-header {
    .menu-btn {
        border: 0;
        padding: 0;
        background: transparent;
        margin-right: 10px;
    }

    .page-title-wrap {
        display: flex;
        gap: 10px;
        align-items: center;

        .page-title {
            font-size: 22px;
            margin-bottom: 0;
            font-weight: $font-bold;

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }

        .goto-back {
            display: none;
        }

        &.show-back-arrow {
            .goto-back {
                display: block;
                cursor: pointer;
                color: #333;
            }
        }
        .progress{
            width: 100%;
            height: 10px;
           
            .progress-bar {
                background: linear-gradient(113deg, #DADBF3 10.2%, #5C5FA1 85.12%);
                width: 100%;
                background-size: 200% auto;
                background-position: 0 100%;
                animation: progressBar 3s ease-in-out;
                animation-fill-mode:both; 
                }
                  @keyframes progressBar {
                    0% { width: 0; }
                    100% { max-width: 100%; }
                  }
            }
    }

    box-shadow: 24px 4px 29px 0px rgba(0, 0, 0, 0.06);
    position: fixed;
    top: 0;
    left: 80px;
    width: calc(100% - 80px);
    z-index: 9;
    padding-left: 2px;

    @media (max-width: 767px) {
        width: 100%;
        left: 0;
        z-index: 10;
        padding-left: 0;
    }

    .header-nav {
        padding: 15px 30px;

        @media (max-width: 767px) {
            padding-top: 6px;
            padding-bottom: 6px;
        }

        .navbar {
            padding: 0;
        }

        background-color: #fff;

        .v-devider {
            display: inline-block;
            width: 1px;
            background: #525252;
            height: 20px;
            margin: 0 15px;
        }
    }

    .header-nav {
        .menu-item {
            color: #d8d6d6;
        }
    }

    .profile-dd {
        .dropdown-menu {
            min-width: 200px;
            top: 58px;
            border: 0;
            box-shadow: 0px 3px 10px #0000002b;

            .dropdown-item:hover a,
            .dropdown-item:hover .icon {
                color: #000 !important;
            }
        }

        .btn {
            background-color: #f2f1f1;
            color: #c1c1c1;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            padding: 0;


            &::after {
                border: 0;
            }

            border-radius: 50px;

            height: 45px;
            width: 45px;
            font-size: 0;

            img {
                width: 45px;
                height: 45px;
                object-fit: cover;
                border-radius: 50px;
            }
        }

        .icon {
            width: 18px;
            height: 18px;
            margin-right: -4px;
            color: #4b4b4b;
        }
    }

    .notification-dd {
        .btn {
            background: transparent;
            border: 0;
            color: #d8d6d6;
            padding: 0;

            &::after {
                border: 0;
            }

            &:active {
                background-color: transparent;
                color: $color-primary;
            }

            &:hover {
                color: $color-secondary;
            }

        }

        .btn[aria-expanded="true"] {
            color: $color-primary;
        }

        .dropdown-menu {
            width: 288px;
            padding: 15px 20px;
            max-height: 380px;
            overflow: auto;
        }

        .dropdown-item {
            border-bottom: 1px solid #F2F2F2;
            padding: 10px 0;

            &:last-child {
                border: 0;
            }
        }

        .count {
            display: inline-flex;
            width: 20px;
            height: 20px;
            background-color: #da4d4d;
            color: #fff;
            font-size: 10px;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            font-weight: 600;
            position: absolute;
            left: 15px;
            top: -9px;
        }
    }

    .msg-menu {
        .count {
            display: inline-flex;
            width: 20px;
            height: 20px;
            background-color: #da4d4d;
            color: #fff;
            font-size: 10px;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            font-weight: 600;
            position: absolute;
            left: 15px;
            top: -9px;
        }
    }

    .help-btn {
        background-color: #F8F8FF;
        font-size: 14px;
        color: $color-primary;
        font-weight: $font-medium;
        height: 48px;
        padding: 8px 15px;
    }
}

.cursor-pointer {
    cursor: pointer;

    &:hover {
        color: $color-primary;
    }
}



.callout {
    background-color: $color-primary-light;
    color: $color-primary;
    font-weight: $font-medium;
    border-radius: 6px;
    padding: 10px 7px;
    border-left: 4px solid $color-primary;

    &.blue {
        background-color: #EAF7FF;
        color: #02A0FC;
        border-color: #02A0FC;
    }

    &.green {
        background-color: #EBFDEA;
        color: #0FC000;
        border-color: #0FC000;
    }

    &.purple {
        background-color: #F6F2FF;
        color: $color-secondary;
        border-color: $color-secondary;
    }
}

// App Body 
.login-page {
    // background-image: url('../images/login-bg.png');
    // background-size: cover;
    background-color: $color-primary-dark;

    .min-vh-100 {
        min-height: 110.4vh !important;
    }
}

.app-body {
    background: var(--Background-Gradient, linear-gradient(180deg, #FCFCFC 0%, #F9F2F7 46.88%, #FFF 100%));
    padding-left: 15px;
    padding-right: 15px;
}

.page-container {
    max-width: 1300px;
    margin: 0 auto;
}

.form-check {
    .form-check-input {
        width: 16px;
        height: 16px;
        background-size: 16px;
        border-color: $color-primary;

        &:checked {
            background-color: $color-primary;
            border-color: $color-primary;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .form-check-label {
        font-size: 14px;
        margin-top: 3px;
    }

    &.label-font-12 {
        .form-check-label {
            font-size: 12px;
        }
    }

    .form-check-input:checked[type=radio] {
        // background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='5' cy='5' r='5' fill='%235C5FA1'/%3e%3c/svg%3e ");
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4.5' cy='4.5' r='3.5' fill='%235D61A2' stroke='white' stroke-width='2'/%3e%3c/svg%3e ");
        background-color: #fff;
        background-size: 100%;
    }

    .form-check-input[type=radio]+.form-check-label {
        margin-top: 2px;
    }

}
// Custome Pophover 
.custom-pophover{
    display: inline;
    .btn{
        background-color: transparent;border: 0;padding: 0;
        line-height: 14px;
        vertical-align: text-bottom;
        &::after{content: none;}
        &:active{background-color: transparent !important;}
       
    }
    
    .dropdown-menu{
        font-size: 15px;
        color: $color-grey;
        padding: 15px;
        line-height: 22px;
        box-shadow: -2px 4px 29px 0px #00000024 !important;
        min-width: 280px;
        border: 0;
        border-radius: 15px;
      
    }
     &.show{
        .btn::after{
            content: '';
            display: block;  
            position: absolute;
            right: -9px;
            top: 50%;
            margin-top: -4px;
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-right: 7px solid #ffffff;
            border-bottom: 7px solid transparent;
            border-left: 7px solid transparent;
            z-index: 99999;
            transition: 0.4s;
        }
     }
     
     
}


// date picker 

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border: 0;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #fff !important;
}

.react-datepicker {
    font-family: 'Outfit', sans-serif !important;
    width: 306px;
    padding: 12px 15px;
    border: 0 !important;
    box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px !important;

    .react-datepicker__current-month {
        // font-size: 12px;
        // font-weight: 400;
        // text-align: left;
        // padding-bottom: 10px;
        // padding-left: 10px;
        display: none;
    }

    .react-datepicker__navigation-icon {
        top: 15px;

    }

    .react-datepicker__navigation-icon::before {
        border-width: 2px 2px 0 0;
        height: 7px;
        width: 7px;
        top: 8px;
    }


    .react-datepicker__navigation.react-datepicker__navigation--previous {
        right: 30px;
        left: auto;
    }

    .react-datepicker__day-names {
        border-top: 1px solid #F2F2F2;

        .react-datepicker__day-name {
            font-size: 10px;
            text-transform: uppercase;
            color: #1B1925;
        }
    }

    .react-datepicker__header {
        background-color: #fff !important;
        border-bottom: 0;
        text-align: left;

        .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {

            .react-datepicker__month-dropdown-container--select,
            .react-datepicker__year-dropdown-container--select {
                margin: 0;
            }
        }

        .react-datepicker__header__dropdown {
            padding: 0;
            display: inline-block !important;
            margin-bottom: 10px !important;
        }

        select {
            border: 0;
            font-size: 12px;
            margin-right: 10px;

            &:focus-visible {
                outline: none;
            }
        }
    }

    .react-datepicker__month {
        margin: 0 !important;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 6px;
        font-size: 14px;
        border-radius: 50px;
    }

    .react-datepicker__day--outside-month {
        color: #DBDBDB;
    }

    .react-datepicker__day--selected {
        background-color: $color-primary;
        font-weight: $font-regular;
        color: #fff !important;
    }

}

.form-switch {
    .form-check-input {
        width: 36px;
        height: 20px;
        background-color: #f2f2f2;
        border: 0;
        background-position: 2px 1px !important;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-circle-fill' viewBox='0 0 16 16'%3e%3ccircle cx='8' cy='8' r='8'/%3e%3c/svg%3e");
        background-size: 18px;
        zoom: 1.01;
        &:checked {
            background-color: $color-primary;
            background-position: 17px !important;
        }
    }

    .form-check-label {
        margin-left: 7px;
    }
}


.form-range::-webkit-slider-thumb {
    background: $color-primary;
}

.form-range::-moz-range-thumb {
    background: $color-primary;
}

.form-range::-ms-thumb {
    background: $color-primary;
}


.form-control,
.form-select {
    &:focus {
        box-shadow: none;
        border-color: #ddd;
    }

    &::placeholder {
        font-size: 14px;
        color: #929295;
    }

    &:visited {
        border-color: $color-primary !important;
    }

}


.text-link {
    text-decoration: underline;
    font-weight: $font-medium;
    &:hover{color: $color-primary-dark !important;}
}

select:not(:valid) {
    color: #999 !important;
}

.form-style-2 {

    .form-control,
    .form-select {
        height: 44px;
        border-radius: 7px !important;
        z-index: 0 !important;
        font-size: 14px;
    }

    .form-label {
        margin-bottom: 16px;
        line-height: 16px;
    }

    .overlap-label {
        position: relative;

        .form-label {
            position: absolute;
            font-size: 12px;
            left: 10px;
            top: -9px;
            background: #fff;
            padding: 0 4px;
            z-index: 1;
        }

        &.input-group {
            .form-control {
                border-radius: 7px 0px 0px 7px !important;
            }
        }
    }

}

.form-control.rounded-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.form-control.rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.country-code-inpute {
    max-width: 87px;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active {
    background-color: $color-primary;
    border-color: $color-primary;
    color: #fff;

    &:hover {
        color: #fff !important;
    }
}

.btn-danger {
    background-color: #FF3A29;
    border-color: #FF3A29;
    font-size: 16px;
    font-weight: $font-medium;
}

.btn-check+.btn:hover {
    border-color: $color-primary;
    color: $color-primary !important;
}

.card {
    border-radius: 20px;
    border: 0;
    background-color: #fff;

    &.card-shadow {
        box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.09);
    }

    &.card-padding {
        padding: 30px;
    }
}

.steps-card {
    position: relative;
    border-radius: 6px;
    padding: 4px 15px;

    .side-border {
        width: 6px;
        height: 100%;
        border-radius: 6px;
        position: absolute;
        background-color: $form-control-border;
        left: 0;
        top: 0;
    }

    .title {
        font-size: 14px;
        font-weight: $font-medium;
        margin-bottom: 1px;
        color: $form-control-border;
    }

    .description {
        font-size: 14px;
        margin-bottom: 0;
        color: $form-control-border;
    }

    &.active {
        .title {
            color: $color-primary;
        }

        .description {
            color: #929295;
        }

        .side-border {
            background-color: $color-primary;
        }
    }

    @media (max-width: 767px) {
        padding: 0;
        width: 100%;
        margin-bottom: 0 !important;

        .title,
        .description {
            display: none;
        }

        .side-border {
            width: 100%;
            height: 6px;
        }

    }
}

.option-dd {
    button {
        background-color: transparent;
        color: #929295;
        border: 0;
        padding: 0;

        &::after {
            border: 0;
        }

        &:hover,
        &:active {
            color: $color-primary !important;
            background: transparent !important;
        }

        &.show {
            color: $color-primary;
            background: transparent;
        }
    }
}

.dropdown-menu.show {
    border: 0;
    box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);

    .dropdown-item {
        font-size: 14px;
        color: #a7a7a7;
    }
}



.sort-dd {
    padding: 7px 0 6px 11px;
    border: 1px solid #ddd;
    border-radius: 7px;
    display: flex;
    align-items: center;
    gap: 5px;

    .form-select {
        border: 0;
        padding: 0 32px 0 0;
        font-size: 14px;
    }

    .label {
        white-space: nowrap;
        color: #929295;
    }
}

.search-bar {
    background-color: #F9F9F9;
    border-radius: 8px;

    .input-group-text {
        background-color: transparent;
        border-right: 0;
        border-color: #F9F9F9;
        border-radius: 8px 0 0 8px;
    }

    .form-control {
        border-left: 0;
        background: transparent;
        border-color: #F9F9F9;
        border-radius: 0 8px 8px 0;

        &::placeholder {
            font-size: 14px;
            color: #989898;
        }
    }
}

.pagination {
    gap: 5px;

    .page-link {
        border-radius: 5px;
        color: #333;
        border-color: $form-control-border;
        padding: 3px 11px;
    }

    .page-item.active {
        .page-link {
            background-color: #fff;
            border-color: $color-primary;
            color: $color-primary;
        }
    }
}

.offcanvas {
    width: 500px !important;

    .offcanvas-body {
        .nav-link {
            font-size: 12px;
        }
    }
}

.modal-dialog {
    .modal-content {
        border-radius: 16px;
    }

    .modal-header {
        border: 0;
        padding: 30px 30px 15px 30px;

        @media (max-width:767px) {
            padding: 20px 15px 15px 15px;
        }
    }

    .modal-title {
        font-size: 20px;
        line-height: 19px;

        @media (max-width:767px) {
            font-size: 18px !important;
            line-height: 27px;
        }
    }

    .modal-body {
        padding: 15px 30px 30px 30px;

        @media (max-width:767px) {
            padding: 15px 15px 20px 15px;
        }
    }
}

.btn-checkbox {
    position: relative;
    padding-left: 0;

    .form-check-input {
        position: absolute;
        left: 35px;
        top: 10px;

    }

    .form-check-label {
        border: 1px solid #F2F2F2;
        padding: 8px 20px 9px 37px;
        border-radius: 6px;
        cursor: pointer;
        box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);
    }
}

.custom-search-select {
    .css-13cymwt-control {
        background-color: #F9F9F9;
        border-color: #F9F9F9 !important;
    }

    .css-1fdsijx-ValueContainer {
        background-image: url(../../assets/images/search-icon.svg);
        background-size: 18px;
        background-repeat: no-repeat;
        padding-left: 45px;
        background-position: 15px center;
    }
}

.upload-btn-container {
    position: relative;

    img {
        width: 200px;
        height: auto;
    }

    input {
        display: none;
    }

    label {
        width: 100%;
        height: 55px;
        background: #F9F9F9;
        border: 1px dashed $form-control-border;
        color: #5d5d5d;
        font-size: 14px;
        text-transform: capitalize;
        border-radius: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        &:hover {
            border-color: $color-primary;
        }
    }

    .tb-img-view {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.table-style {
    thead th {
        background-color: #F9F9F9;
        color: #929295;
        font-size: 12px;
        font-weight: $font-medium;
        vertical-align: middle;
        padding: 13px 20px;
        border-color: #F2F2F2;

        .form-check-input {
            font-size: 14px;
        }
    }

    tbody tr td {
        color: #929295;
        vertical-align: middle;
        padding: 14.5px 20px;
        border-color: #F2F2F2;

        .progress {
            height: 9px;

            .progress-bar {
                background-color: $color-primary;
            }
        }

    }

    tbody tr:last-child td {
        border: 0;
        padding-bottom: 0;
    }
}

.tab-style-1 {
    gap: 15px;

    .nav-link {
        color: #1B1925;
        font-size: 16px;
        border: 0;
        border-bottom: 2px solid transparent;
        padding: 8px 0;


        &.active {
            border-color: $color-primary ;
            color: $color-primary;
        }
    }

    @media (max-width:767px) {
        gap: 10px;

        .nav-link {
            font-size: 14px;
            padding: 5px 0;
        }
    }
}

.tab-style-2 {
    .nav-item {
        .nav-link {
            border-radius: 0;
            color: #1B1925;
            font-size: 16px;
            background-color: #F9F9F9F9;
            font-weight: $font-medium;
            // border-bottom: 1px solid #f1efef;
            border-bottom: 1px solid #F2F2F2;
            padding: 21.5px 24px;

            .icon {
                vertical-align: text-bottom;
                margin-right: 10px;
                width: 14px;
                height: 14px;
                filter: grayscale(1);
                opacity: 0.3;
            }

            &.active {
                background-color: #fff;

                .icon {
                    filter: grayscale(0);
                    opacity: 1;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .nav-item .nav-link {
            padding: 10px 15px;
            font-size: 14px;
        }
    }
}

.tab-style-3 {
    .nav {
        background-color: #F9F9F9;
        border-radius: 6px;
        padding: 2px;
    }

    .nav-link {
        color: $color-grey;
        font-size: 16px;

        &.active {
            background-color: #fff;
            color: #000;
            font-weight: $font-medium;
        }
    }
}

.active-status {
    position: absolute;
    bottom: 5px;
    right: -4px;
    padding: 1px;
    background: #fff;
    border-radius: 50px;

    &.offline {
        color: #bababa;
    }

    &.online {
        color: #39b834;
    }
}

.sidebar-tab-style {

    .nav-link {
        padding: 14px;
        color: #000;
        border-radius: 0;

        &.active {
            background-color: $color-primary-light;
            color: #000;
        }
    }

    .nav-item:last-child .active {
        border-radius: 0 0 12px 12px;
    }
}

.accordion-style {
    box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    .accordion-body {
        border-bottom: 1px solid #F2F2F2;
    }

    .accordion-item {
        border-bottom: 0;
        border-color: #F2F2F2;
    }

    .accordion-button {
        font-size: 16px;
        font-weight:
            $font-medium;
        padding: 20px 45px;
        box-shadow: none;
        border-bottom: 1px solid #F2F2F2;

        &:focus {
            box-shadow: none;
        }

        &::after {
            position: absolute;
            left: 15px;
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
            background-size: 16px;
            background-position: center;
        }
    }
}

.accordion-style-3 {
    // box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    .accordion-body {
        border-bottom: 1px solid #F2F2F2;
        color: #1B1925;
        font-weight: 300;
    }

    .accordion-item {
        border: 0 !important;
        border-top: 1px solid #ddd !important;
        // border-color: #F2F2F2;
    }

    .accordion-button {
        font-size: 14px;
        font-weight:
            $font-medium;
        padding: 20px 0px;
        box-shadow: none;
        // border-bottom: 1px solid #F2F2F2;

        &:focus {
            box-shadow: none;
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: #fff;
        color: #1B1925;


        // &::after {
        //     transform: rotate(0deg);
        // }
    }
}

.status-badge {
    border-radius: 6px;
    padding: 6px 10px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: $font-medium;
    background-color: #ddd !important;
    color: #333;
    line-height: 13px;
    display: inline;
    height: 25px;

    &.progress {
        background-color: #EAF7FF !important;
        color: #02A0FC;
    }

    &.not-started {
        background-color: #FFFAED !important;
        color: #FFB200;
    }

    &.completed {
        background-color: #EBFDEA !important;
        color: #0FC000;
    }

    &.danger {
        background-color: #FFEAE9 !important;
        color: #FF3A29;
    }

    &.tag1 {
        background-color: #EAF7FF !important;
        color: #1B1925;
    }

    &.tag2 {
        background-color: #EBFDEA !important;
        color: #1B1925;
    }

    &.tag3 {
        background-color: #FFFAED !important;
        color: #1B1925;
    }

    &.tag4 {
        background-color: #F6F2FF !important;
        color: #1B1925;
    }

    &.tag5 {
        background-color: #F4F7FD !important;
        color: #1B1925;
    }


}

.progress-small {
    height: 6px;
    background-color: #F6F2FF;

    .progress-bar {
        background: #A48EE5 !important;
    }
}

.progress-sm {
    height: 9px;

    .progress-bar {
        background: $color-primary
    }
}

.priority-btns {
    gap: 10px;

    .btn {
        @extend .status-badge;
        border-radius: 7px !important;
        line-height: 10px;
        border-color: transparent;

        &.btn-low {
            background-color: #EBFDEA !important;
            color: #0FC000;

            &:hover {
                color: #0FC000 !important;
                border-color: #0FC000
            }
        }

        &.btn-medium {
            background-color: #FFFAED !important;
            color: #FFB200;

            &:hover {
                color: #FFB200 !important;
                border-color: #FFB200
            }
        }

        &.btn-high {
            background-color: #FFEAE9 !important;
            color: #FF3A29;

            &:hover {
                color: #FF3A29 !important;
                ;
                border-color: #FF3A29
            }
        }
    }

    .btn-check:checked+.btn-high {
        border-color: #FF3A29;
    }

    .btn-check:checked+.btn-medium {
        border-color: #FFB200;
    }

    .btn-check:checked+.btn-low {
        border-color: #0FC000;
    }

}

.alert-notification {
    background-color: $color-primary;
    border: 0;
    position: absolute;
    right: 15px;
    z-index: 1;

    .alert-heading {
        font-size: 14px;
        color: #fff;
        font-weight: $font-bold;
        margin-bottom: 5px;
    }

    .decription {
        font-size: 12px;
        color: #fff;
    }

    .btn-close {
        filter: brightness(0) invert(1);
    }
}

.react-tags {
    margin-bottom: 15px;

    .react-tags__combobox {
        .react-tags__combobox-input {
            height: 45px;
            width: 100% !important;
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 6px;

            &:focus-visible {
                outline: none;
            }
        }

    }

    .react-tags__listbox {
        background-color: #ece9e9;
        max-height: 180px;
        overflow-y: scroll;
        border: 1px solid hsl(237, 27%, 50%);
        border-radius: 3px;

        .react-tags__listbox-option {
            background-color: hsl(237, 27%, 50%);
            padding: 5px;
            cursor: pointer;
            color: #fff;

            &:hover {
                background-color: #fff;
                color: #000;
            }
        }
    }

    .react-tags__list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
        padding-left: 0;

        .react-tags__tag {
            border: 0;
            border-radius: 20px;
            padding: 3px 21px 3px 12px;
            font-size: 11px;
            background-color: $color-primary;
            color: #fff;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
            background-size: 9px;
            background-repeat: no-repeat;
            background-position: calc(100% - 6px);
        }
    }
}

.rangeslider-horizontal {
    height: 7px !important;
    box-shadow: none !important;
    background-color: #F2F2F2 !important;

    .rangeslider__fill {
        background-color: $color-primary !important;
    }

    .rangeslider__handle {
        height: 18px !important;
        width: 18px !important;
        box-shadow: none !important;
        border: 0 !important;
        background-color: $color-primary !important;

        &::after {
            content: none !important;
        }
    }

}

.modal.size-md {
    .modal-dialog {
        max-width: 600px;
    }
}

// Image Grid Gallery 

.image-grid-galery {
    video {
        border: 1px solid #ddd;
        border-radius: 8px;
    }

    .image {
        border: 1px solid #f6f5f5;
    }

    .grid-col {
        img {
            border-radius: 16px;
            width: 100%;
            object-fit: cover;
            height: 114px;
        }
    }

    &.gird-layout-1 {
        .grid-col {
            width: 100%;
        }

        .image {
            height: 234px;
        }
    }

    &.gird-layout-2 {
        .grid-col-1 {
            max-width: 70%;
        }

        .grid-col-2 {
            max-width: 30%;
        }

        .image {
            height: 234px;
        }

        @media (max-width:767px) {
            display: block;
            flex-wrap: wrap;

            .grid-col-1 {
                max-width: 100%;
                flex: auto;
                margin-bottom: 10px;

                .image {
                    height: 150px;
                }
            }

            .grid-col-2 {
                flex: auto;
                max-width: 100%;

                .image {
                    height: 150px;
                }
            }
        }
    }

    &.gird-layout-3 {
        display: block;
        overflow: auto;

        .grid-col-1 {
            width: 85%;
            float: left;
        }

        .grid-col-2 {
            width: 15%;
            float: right;

            .image {
                height: 113px;
                margin-bottom: 8px;
            }
        }

        .grid-col-3 {
            width: 15%;
            float: right;

            .image {
                height: 113px;
            }
        }

        .image {
            height: 234px;
        }

        @media (max-width:767px) {
            display: flex;
            flex-wrap: wrap;

            .grid-col-1 {
                width: 100%;
                flex: auto;
                margin-bottom: 10px;

                .image {
                    height: 150px;
                }
            }

            .grid-col-2,
            .grid-col-3,
            .grid-col-4 {
                flex: auto;
                width: 50%;
            }
        }
    }

    &.gird-layout-4 {
        display: block;
        overflow: auto;

        .grid-col-1 {
            width: 52%;
            flex: auto;
            float: left;

            .image {
                height: 234px;
            }
        }

        .grid-col-2 {
            width: 33%;
            float: right;
            float: left;

            .image {
                height: 234px;
            }
        }

        .grid-col-3 {
            width: 15%;
            float: right;
            margin-bottom: 10px;

            .image {
                height: 112px;
            }
        }

        .grid-col-4 {
            width: 15%;
            float: right;

            .image {
                height: 112px;
            }
        }

        @media (max-width:767px) {
            display: flex;
            flex-wrap: wrap;

            .grid-col-1,
            .grid-col-2,
            .grid-col-3,
            .grid-col-4 {
                flex: auto;
                width: 50%;
                margin-bottom: 10px;

                .image {
                    height: 150px;
                }
            }
        }
    }

    &.gird-layout-5 {
        display: inline-block;

        .grid-col-1 {
            width: 70%;
            float: left;

            .image {
                height: 234px;
            }
        }

        .grid-col-2,
        .grid-col-3,
        .grid-col-4,
        .grid-col-5 {
            width: 15%;
            height: 114px;
            float: left;
            margin-bottom: 8px;

            .image {
                height: 114px;
            }
        }

        @media (max-width:767px) {
            display: flex;
            flex-wrap: wrap;

            .grid-col-1 {
                width: 100%;
                flex: auto;
                margin-bottom: 10px;

                .image {
                    height: 150px;
                }
            }

            .grid-col-2,
            .grid-col-3,
            .grid-col-4,
            .grid-col-5 {
                flex: auto;
                width: 50%;
            }
        }
    }
}

.Toastify__toast--success {
    background-color: $color-primary;

    .Toastify__toast-icon {
        margin-right: 15px;

        svg {
            fill: #fff;
        }
    }

    color: #fff;
    font-weight: $font-bold;
    font-size: 14x;
    border-radius: 8px;

    .Toastify__close-button {
        color: #fff;
        opacity: 1;
        width: 28px;
        margin-top: 12px;

        svg {
            height: 20px;
            width: 20px;
        }

    }
}

.Toastify__toast--error {
    @extend .Toastify__toast--success;
    border: 1px solid #FF3A29;
    background-color: #fff;
    color: #FF3A29;

    .Toastify__close-button {
        color: #DBDBDB;
    }

    .Toastify__toast-icon svg {
        fill: #FF3A29;
    }
}

// responsive css
@media (max-width: 767px) {
    .font-xs-24 {
        font-size: 24px;
    }

    .font-xs-16 {
        font-size: 16px !important;
    }

    .font-xs-12 {
        font-size: 12px !important;
    }

    .border-xs-0 {
        border: 0 !important;
    }

    .w-xs-100 {
        width: 100% !important;
    }

    .bg-xs-white {
        background-color: #fff !important;
    }

    .layout-veritcle-menu .verticle-menu {
        left: -250px;
        width: 245px;
        top: 55px;

        .menu-name {
            display: block !important;
            width: 100% !important;
        }

        .menu-item {
            width: auto !important;
            justify-content: flex-start !important;
            gap: 6px;
            padding: 10px;

        }

    }

    .verticle-menu.open-menu {
        left: 0;
    }

    .steps-main-card {
        background-color: transparent !important;
        box-shadow: none !important;
    }

    .rounded-xs-0 {
        border-radius: 0 !important;
    }

    .mo-no-card-style {
        border-radius: 0;
        padding: 0 !important;
        box-shadow: none !important;
        background-color: transparent;
    }

    .app-body {
        background: #fff;
    }
}

.mob-tab-menu {
    position: fixed;
    bottom: 65px;
    right: 15px;
    z-index: 9;

    .dropdown-menu {
        min-width: 300px;
    }

    .dropup {
        .dropdown-toggle::after {
            border: 0;
        }
    }

}

.avtar-list {
    display: flex;

    .profil-img {
        width: 28px;
        height: 28px;
        border-radius: 40px;
        border: 3px solid #fff;
        object-fit: cover;
        margin-left: -12px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.react-select-open {
    width: 100%;

    .css-b62m3t-container {
        width: 100%;
    }

    .no-devider {
        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }

    .down-arrow {
        width: 22px;

        .icon {
            width: 10px;
            height: 6px;
        }
    }

    .css-t3ipsp-control,
    .css-13cymwt-control {
        box-shadow: none;
        border-color: $form-control-border !important;
        border-radius: 8px;
        height: 44px;
        font-size: 12px;
    }

    .css-1jqq78o-placeholder {
        color: #929295;
    }

    .css-tr4s17-option {
        background-color: $color-primary-light;
        color: $color-primary !important;
    }

    .css-tr4s17-option:hover {
        color: $color-primary !important;
    }


    .css-d7l1ni-option,
    .css-10wo9uf-option:active {
        background-color: $color-primary-light;
    }

    &.selected {

        .css-13cymwt-control,
        .css-t3ipsp-control {
            border-color: $color-primary !important;
        }
    }

    &.truncate-data {
        .form-control {
            padding-right: 30px;
        }
    }
}

.css-1nmdiq5-menu {
    z-index: 2 !important;
}

.react-datepicker-popper {
    z-index: 2 !important;
}

.progress-steps {
    .steps-item {
        width: 6px;
        height: 48px;
        border-radius: 3px;
        background-color: #C1C3C7;
        margin-bottom: 10px;

        &.active {
            background-color: $color-primary;
            cursor: pointer;
        }
    }

    @media (max-width:767px) {
        display: flex;
        gap: 10px;

        .steps-item {
            width: 100%;
            height: 7px;
        }
    }

}

.mt-14 {
    margin-top: 1.4rem !important;
}

.school-cosidering {
    position: relative;

    .del-icon {
        position: absolute;
        right: 23px;
    }
}

.popover {
    border: 0;
    box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.11);
}

.editor-box {
    .ck-content {
        min-height: 150px;
    }
}

.search-dropdown {
    .input-group-text {
        position: absolute;
        top: 3px;
    }

    .form-control {
        padding-left: 35px;
    }
}

.file-drop-zone {
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;
    border-radius: 16px;
    border: 1px dashed $form-control-border;
    background: #F9F9F9;
}

.file-drop-zone.dragging {
    border-color: $color-primary;
    background-color: #fff;
    box-shadow: 0px 0px 10px #0000001f;
}

.mb-12 {
    margin-bottom: 12px !important;
}

// ipad Css 
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .page-onboarding .progress-steps {
        left: 55px !important;
    }

    .site-header .header-nav {
        padding: 15px;
    }

    .verticle-menu.open-menu {
        width: 245px;

        .menu-item {
            width: auto !important;
            justify-content: flex-start !important;
            gap: 6px;
            padding: 10px;
        }

        .menu-name {
            display: block !important;
            width: 100%;
        }

    }

    .step-div {
        padding: 8px 28px;
    }
}

.input_selected {
    border-color: $color-secondary-2;
}

.react-university-select {
    .css-1dimb5e-singleValue {
        padding-right: 15px;
        font-size: 14px;
    }
}

.task-loader-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 220px;
    width: 355px;

    .image,
    h2,
    p,
    .price-card,
    .loading-card-footer {
        border-radius: 5px;
        background: #eee;
        background: linear-gradient(110deg, #ececec 12%, #f5f5f5 18%, #ececec 33%);
        background-size: 200% 100%;
        animation: 1s shine linear infinite;
    }
}

.community-loader-card {
    .load {
        border-radius: 5px;
        background: #eee;
        background: linear-gradient(110deg, #ececec 12%, #f5f5f5 18%, #ececec 33%);
        background-size: 200% 100%;
        animation: 1s shine linear infinite;
    }
}

.p-10 {
    padding: 10px !important;
}

.modal-size-660 {
    .modal-dialog {
        min-width: 663px !important;
    }
}

.graph-dd {
    width: fit-content;
    ;

    .form-control {
        border: 0 !important;
    }
}
.logout-modal{
    .modal-dialog{
        width: 410px;
    }
}

.star-ratings {
    .star {
        stroke: #5d5fa1;
    }
}

.no-options-async {
    color: #000;
    background-color: #A48EE5;
    padding: 60px 0px;
    text-align: center;
    width: 100%;
    // border: 1px solid var(--grey);
    // box-shadow: var(--shadow);
}

.sm-arrow .down-arrow img {
    width: 10px !important;
    height: 6px !important;
  }

  
.dropdown-full-width ul.options {
    width: 376px !important;
    margin-top: 0px;
    left: -107px !important;
}