 @import '../../assets/css/variables.scss';

 .page-req-tracker {

  .uploaded-doc-card{
    border-color: transparent;
    background-color: transparent;
    border: 1PX solid transparent;
    &:hover{
      background-color: $color-primary-light;
      border-color: $form-control-border;
    }
  }

   .mandotroy {
     .form-check-label::after {
       content: " *";
       color: red;
     }
   }

   .progress {
     height: 9px;

     .progress-bar {
       background-color: $color-primary
     }
   }

   .detais-accordion {
     box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);

     .accordion-item {
       border-color: #F2F2F2;

       .accordion-button {
         font-size: 16px;
         font-weight: $font-medium;
         padding: 24px;
         &:focus {
           box-shadow: none;
         }

         &::after {
           content: none;
         }

         &::before {
           flex-shrink: 0;
           width: 14px;
           height: 14px;
           margin-right: 14px;
           content: "";
           background-image: var(--bs-accordion-btn-icon);
           background-repeat: no-repeat;
           background-size: 14px;
           transition: var(--bs-accordion-btn-icon-transition);
           -webkit-transform: rotate(-90deg);
           -moz-transform: rotate(-90deg);
           -ms-transform: rotate(-90deg);
           -o-transform: rotate(-90deg);
           transform: rotate(-90deg);
         }
       }

       .accordion-button:not(.collapsed) {
         background-color: #fff;
         color: #000;
         &::before {
          flex-shrink: 0;
          width: 14px;
          height: 14px;
          margin-right: 14px;
          content: "";
          background-image: var(--bs-accordion-btn-icon);
          background-repeat: no-repeat;
          background-size: 14px;
          transition: var(--bs-accordion-btn-icon-transition);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
       }
     }
   }

 }
 .step-wizard{
  display: flex;
  gap: 15px;
  .step-div{
    width: 100%;
    position: relative;
    padding: 8px 40px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ddd;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    .step-name{color: #1B1925;font-size: 14px;font-weight: $font-medium;margin-bottom: 0;}
    .description{color: #929295;font-size: 12px;margin: 0;}
    &::after{
      content: "";
      position: absolute;
      right: -14px;
      top: 0px;
      width: 17px;
      height: 60px;
      background-image: url('../../assets/images/right-corner-outline.svg');
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &::before{
      content: "";
      position: absolute;
      left: -1px;
      top: -1px;
      width: 17px;
      height: 83px;
      background-image: url('../../assets/images/right-corner-outline.svg');
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &.completed{
      background-color: $color-primary;
      border-radius: 7px;
      .step-name{color: #fff;}
    .description{color: #fff;}
      &::after{
        background-image: url('../../assets/images/right-corner-fill.svg');
        height: 58px;
        right: -14px;
        border-radius: 10px;
        top: -1px;
      }
    }
  }
  @media (max-width: 767px) {
    display: block;
  }
}

.modal.size-960{
  .modal-dialog{max-width: 960px;}
}
.card-danger{
  background-color: #FFEAE9;
  border-color: #FF3A29 !important;
  .close{color: #FF3A29 !important;}
}
.counselors-btn{
  display: inline-flex;
  gap: 10px;
  border: 1px solid #F2F2F2;
  border-radius: 30px;
  padding: 5px 10px;
  align-items: center;
  box-shadow: -2px 4px 29px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  .prof-pic{border-radius: 50px;width: 32px; height: 32px;object-fit: cover;}
  .name{font-size: 16px;    margin-right: 5px;}
  &.selected{background-color: #F4F7FD;border-color: $color-primary;color: $color-primary;}
  &.disabled{opacity: 0.7;}

}